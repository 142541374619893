<template>
  <div>
    <!-- Filters -->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5 class="mb-1">Filtros</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group label="Nome" label-for="searchName">
              <b-form-input
                id="searchName"
                v-model="name"
                @keyup.enter="search"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="CNPJ" label-for="searchCnpj">
              <b-form-input
                id="searchCnpj"
                v-model="cnpj"
                v-mask="'##.###.###/####-##'"
                @keyup.enter="search"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="Produto" label-for="searchProduct">
              <v-select
                id="searchProduct"
                multiple
                v-model="product"
                :reduce="(product_list) => product_list.id"
                :options="products"
                :close-on-select="false"
                label="name"
                dir="ltr"
                @keyup.enter="search"
              >
                <template #no-options="{ search, searching, loading }">
                  Sem resultados
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mb-md-0 mb-2 text-right">
            <b-button
              variant="outline-primary"
              @click.prevent="search"
              :disabled="loading"
            >
              <b-spinner v-if="loading" small />
              <feather-icon v-else icon="SearchIcon" class="mr-50" />
              <span>
                {{ loading ? "Aguarde..." : "Pesquisar" }}
              </span>
            </b-button>
            &nbsp;
            <b-button
              variant="outline-secondary"
              @click.prevent="clearSearch"
              :disabled="loading"
              v-text="'Limpar'"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibir</label>
            <v-select
              v-model="itemsPerPage"
              dir="ltr"
              :options="itemsPerPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="search(currentPage)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <label>itens</label>
          </b-col>
          <!-- Search -->
          <b-col v-if="createPartnerCompanyEnabled" cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-button variant="primary" :to="{ name: 'company-save' }">
                <feather-icon icon="PlusCircleIcon" class="mr-50" />
                <span class="text-nowrap">Nova empresa</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        hover
        class="position-relative"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Não foram encontradas empresas para esta busca"
        :busy="loading"
      >
        <!-- Column: name -->
        <template #cell(name)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.name
            }}</span>
          </div>
        </template>
        <!-- Column: cnpj -->
        <template #cell(cnpj)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.doc_cnpj | cpfCnpj
            }}</span>
          </div>
        </template>
        <!-- Column: produtos -->
        <template #cell(product)="data">
          <div class="text-wrap">
            <div class="align-text-top text-capitalize">
              <span
                v-for="product in data.item.products"
                :key="`product-${product.id}`"
              >
                {{ product.name }}
                <br />
              </span>
            </div>
          </div>
        </template>
        <!-- Column: representante -->
        <template #cell(agent)="data">
          <div class="text-wrap">
            <div class="align-text-top text-capitalize">
              <span
                v-for="agent in data.item.agents"
                :key="`agent-${agent.id}`"
              >
                <b-button
                  :id="`agent-pop-icon-${agent.id}`"
                  class="btn-icon"
                  variant="flat"
                  style="padding: 0"
                >
                  <feather-icon
                    size="16"
                    icon="ChevronsUpIcon"
                    class="cursor-pointer"
                  />
                </b-button>
                <user-detail-popover
                  :target="`agent-pop-icon-${agent.id}`"
                  type="company_agent"
                  :id="agent.id"
                  :showWhastapp="true"
                />
                <span class="align-text-top text-capitalize">
                  {{ agent.name }}
                </span>
                <br />
              </span>
            </div>
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            right
            v-if="updatePartnerCompanyEnabled || deletePartnerCompanyEnabled"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="updatePartnerCompanyEnabled"
              :to="{ name: 'company-save', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="deletePartnerCompanyEnabled"
              @click="deleteItem(data.item)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Remover</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Exibindo {{ pageItemsFrom }} à {{ pageItemsTo }} de
              {{ totalItems }} itens</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="itemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="search"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
  BFormGroup,
  BPopover,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { avatarText } from "@core/utils/filter";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {
  CREATE_ACTION,
  UPDATE_ACTION,
  DELETE_ACTION,
  PARTNER_COMPANY_RESOURCE,
  READ_ACTION,
} from "@/constants/resources";
import { onlyDigits } from "../../../helpers/converters";
import UserDetailPopover from "@/modules/shared/components/UserDetailPopover";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BFormGroup,
    BPopover,
    vSelect,
    UserDetailPopover,
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  data() {
    return {
      loading: false,
      tableColumns: [
        { key: "name", label: "Nome", stickyColumn: true },
        { key: "cnpj", label: "CNPJ" },
        { key: "product", label: "Produtos" },
        { key: "agent", label: "Representantes" },
        { key: "actions", label: "Ações" },
      ],
      currentPage: 1,
      itemsPerPage: 1,
      name: "",
      cnpj: "",
      product: "",
    };
  },
  computed: {
    ...mapGetters({
      partnerCompaniesSearchResult: types.PARTNER_COMPANIES_SEARCH_RESULT,
      products: types.PRODUCTS,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
    }),
    readPartnerCompanyEnabled: function () {
      return this.$can(READ_ACTION, PARTNER_COMPANY_RESOURCE);
    },
    createPartnerCompanyEnabled: function () {
      return this.$can(CREATE_ACTION, PARTNER_COMPANY_RESOURCE);
    },
    updatePartnerCompanyEnabled: function () {
      return this.$can(UPDATE_ACTION, PARTNER_COMPANY_RESOURCE);
    },
    deletePartnerCompanyEnabled: function () {
      return this.$can(DELETE_ACTION, PARTNER_COMPANY_RESOURCE);
    },
    items: function () {
      return this.partnerCompaniesSearchResult?.data || [];
    },
    totalItems: function () {
      return this.partnerCompaniesSearchResult?.total || 0;
    },
    pageItemsFrom: function () {
      return this.partnerCompaniesSearchResult?.from || 0;
    },
    pageItemsTo: function () {
      return this.partnerCompaniesSearchResult?.to || 0;
    },
  },
  mounted() {
    this.itemsPerPage = this.initialItemsPerPage;
    this.search();
    this.getAllProducts().catch(() => {
      this.toast({
        component: ToastificationContent,
        props: {
          title: "Oops!",
          text: "Ocorreu um erro ao carregar os tipos de agentes para seleção. Entre em contato com o setor de TI.",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    });
  },
  methods: {
    ...mapActions({
      searchPartnerCompanies: types.SEARCH_PARTNER_COMPANIES,
      deletePartnerCompany: types.DELETE_PARTNER_COMPANY,
      getAllProducts: types.GET_ALL_PRODUCTS,
    }),
    search(currentPage) {
      this.loading = true;
      const { name, cnpj, product } = this;
      this.searchPartnerCompanies({
        name,
        cnpj: onlyDigits(cnpj),
        product,
        limit: this.itemsPerPage,
        currentPage: Number.isInteger(currentPage)
          ? currentPage
          : this.currentPage,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar as empresas. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteItem(item) {
      this.$swal({
        title: "Tem certeza?",
        text: `Você removerá a empresa ${item.name}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary ml-1",
          htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
        },
      }).then((result) => {
        if (result.value) {
          this.deletePartnerCompany(item.id)
            .then((response) => {
              this.search();
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Sucesso",
                  text: `A empresa ${item.name} foi removida`,
                  icon: "CoffeeIcon",
                  variant: "success",
                },
              });
            })
            .catch((error) => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Oops!",
                  text: `Ocorreu um erro ao remover a empresa ${item.name}. Entre em contato com o setor de TI.`,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    clearSearch() {
      this.name = "";
      this.cnpj = "";
      this.product = "";
      this.search();
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
